import React, { Fragment } from 'react';
import Routing from './Routing';
import './App.css';
import { AuthProvider } from './context/AuthProvider';

function App() {
  return (
    <Fragment>
      <AuthProvider>
        <Routing />
      </AuthProvider>
    </Fragment>
  );
}

export default App;
