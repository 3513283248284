import React, { useState, Fragment } from "react";

import Slider from "react-slick";

import bg1 from "../../assets/utils/images/originals/1.jpg";
import bg2 from "../../assets/utils/images/originals/2.jpg";
import bg3 from "../../assets/utils/images/originals/3.jpg";
import { loginImage, logoKementan } from "../../assets";
import { CountdownCircleTimer } from "react-countdown-circle-timer";


import {
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Input,
  TabContent,
  TabPane,
  Card,
  CardHeader,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";

import Swal from "sweetalert2";
import classnames from "classnames";
import axios from "axios";

const API_URI = `${process.env.REACT_APP_API_URL}`;

const Login = () => {
  const [activeTab, setActiveTab] = useState("1");

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    initialSlide: 0,
    autoplay: true,
    adaptiveHeight: true,
  };

  const [otp1, setOtp1] = useState("");
  const [otp2, setOtp2] = useState("");
  const [otp3, setOtp3] = useState("");
  const [otp4, setOtp4] = useState("");
  const [otp5, setOtp5] = useState("");
  const [otp6, setOtp6] = useState("");

  const [open, setOpen] = useState(false);

  const [form, setForm] = useState({
    email: null,
    emailKomtek: null,
    noHp: null,
    noHpKomtek: null,
  });

  const [errEmail, setErrEmail] = useState(false);
  const [errNohp, setErrNohp] = useState(false);
  const [errEmailKomtek, setErrEmailKomtek] = useState(false);
  const [errNohpKomtek, setErrNohpKomtek] = useState(false);

  const closeModal = () => {
    setOpen(false);
  };

  const toggle = (tab) => {
    setForm({
      email: null,
      noHp: null,
    });
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const handleChange = (e, name) => {
    if (name === "email") {
      if (e.target.value) {
        setErrEmail(false);
      } else {
        setErrEmail(false);
      }
      setForm({ ...form, email: e.target.value });
    } else if (name === "noHp") {
      if (e.target.value) {
        setErrNohp(false);
      } else {
        setErrNohp(false);
      }
      setForm({
        ...form,
        noHp: e ? e.target.value.replace(/[^0-9]/g, "") : "",
      });
    } else if (name === "noHpKomtek") {
      if (e.target.value) {
        setErrNohpKomtek(false);
      } else {
        setErrNohpKomtek(false);
      }
      setForm({
        ...form,
        noHpKomtek: e ? e.target.value.replace(/[^0-9]/g, "") : "",
      });
    }
    if (name === "emailKomtek") {
      if (e.target.value) {
        setErrEmailKomtek(false);
      } else {
        setErrEmailKomtek(false);
      }
      setForm({ ...form, emailKomtek: e.target.value });
    }
  };
  const handleSubmit = async () => {
    console.log(activeTab, "tab");

    if (activeTab === "1") {
      if (form.email && form.noHp) {
        const body = {
          email: form.email,
          telp: form.noHp,
        };

        const res = await axios
          .post(`${API_URI}/login`, body)
          .catch(function (error) {
            let errorString = "";
            if (error) {
              let response = error.response;
              errorString = "<ul>" + response?.data?.message ?? " ";
              // console.log(response.data.errors, 'asd')
              if (response?.data?.errors) {
                for (var key of Object.keys(response?.data?.errors)) {
                  errorString +=
                    "<li>" + response?.data?.errors[key][0] + "</li>";
                  // console.log(response.data.errors[key][0])
                }
                errorString += "</ul>";
              } else {
                errorString = response?.data?.message;
              }
            }

            Swal.fire({
              icon: "error",
              title: errorString ?? "Error",
              text: "Gagal Login!",
            });
          });

        if (res && res.data && res.data.user && res.data.access_token) {
          console.log(res, "res");
          localStorage.setItem("status", "authenticated");
          localStorage.setItem("user", JSON.stringify(res.data.user));
          localStorage.setItem("token", res.data.access_token);
          localStorage.setItem("isKomtek", 'false');

          setOpen(true);
        }
      } else {
        if (!form.email) {
          setErrEmail(true);
        }
        if (!form.noHp) {
          setErrNohp(true);
        }
        Swal.fire({
          icon: "error",
          title: "Login Gagal!",
          text: "email dan no handphone harus diisi!",
        });
      }
    } else if (activeTab === '2') {
      if (form.emailKomtek && form.noHpKomtek) {
        const body = {
          email: form.emailKomtek,
          telp: form.noHpKomtek,
        };

        const res = await axios
          .post(`${API_URI}/loginKomtek`, body)
          .catch(function (error) {
            let errorString = "";
            if (error) {
              let response = error.response;
              errorString = "<ul>" + response?.data?.message ?? " ";
              // console.log(response.data.errors, 'asd')
              if (response?.data?.errors) {
                for (var key of Object.keys(response?.data?.errors)) {
                  errorString +=
                    "<li>" + response?.data?.errors[key][0] + "</li>";
                  // console.log(response.data.errors[key][0])
                }
                errorString += "</ul>";
              } else {
                errorString = response?.data?.message;
              }
            }

            Swal.fire({
              icon: "error",
              title: errorString ?? "Error",
              text: "Gagal Login!",
            });
          });

        if (res && res.data && res.data.user && res.data.access_token) {
          console.log(res, "res");
          localStorage.setItem("status", "authenticated");
          localStorage.setItem("user", JSON.stringify(res.data.user));
          localStorage.setItem("token", res.data.access_token);
          localStorage.setItem("isKomtek", 'true');

          setOpen(true);
        } 
      } else {
        if (!form.emailKomtek) {
          setErrEmailKomtek(true);
        }
        if (!form.noHpKomtek) {
          setErrNohpKomtek(true);
        }
        Swal.fire({
          icon: "error",
          title: "Login Gagal!",
          text: "email dan no handphone harus diisi!",
        });
      }
    }
  };

  const changeOtp = (name, event) => {
    if (name === "otp1") {
      setOtp1(event.target.value);
    } else if (name === "otp2") {
      setOtp2(event.target.value);
    } else if (name === "otp3") {
      setOtp3(event.target.value);
    } else if (name === "otp4") {
      setOtp4(event.target.value);
    } else if (name === "otp5") {
      setOtp5(event.target.value);
    } else if (name === "otp6") {
      setOtp6(event.target.value);
    }
  };

  const submitOtp = (event) => {
    // const data = new FormData(event.target);
    console.log(otp1, otp2, "sd");
    event.preventDefault();
    if (
      otp1 !== "" &&
      otp2 !== "" &&
      otp3 !== "" &&
      otp4 !== "" &&
      otp5 !== "" &&
      otp6 !== ""
    ) {
      window.location.href = "/";
    } else {
      alert("masukin otp dulu cuy");
    }
  };

  const inputfocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        elmnt.target.form.elements[next].focus();
      }
    } else {
      console.log("next");

      const next = elmnt.target.tabIndex;
      if (next < 6) {
        elmnt.target.form.elements[next].focus();
      }
    }
  };

  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      return <div className="timer">Too lale...</div>;
    }

    return (
      <div className="timer">
        <div className="text">Remaining</div>
        <div className="value">{remainingTime}</div>
        <div className="text">seconds</div>
      </div>
    );
  };

  return (
    <Fragment>
      <div className="h-100">
        <Row className="h-100 g-0">
          <Col lg="6" className="d-none d-lg-block">
            <div className="slider-light">
              <Slider {...settings}>
                <div className="h-100 d-flex justify-content-center align-items-center bg-plum-plate">
                  <div
                    className="slide-img-bg"
                    style={{
                      backgroundImage: "url(" + bg1 + ")",
                    }}
                  />
                  <div className="slider-content">
                    <h3>Peran BSIP dalam Codex</h3>
                    <p>
                      Berperan melakukan pembahasan teknis mengenai substansi yang sedang dibahas pada forum codex, serta menyiapkan data ilmiah yang diperlukan dalam rangka penyusunan posisi Indonesia pada Sidang Komite/ Ad Hoc Codex Intergovernmental Task Force.
                    </p>
                  </div>
                </div>
                <div className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                  <div
                    className="slide-img-bg"
                    style={{
                      backgroundImage: "url(" + bg3 + ")",
                    }}
                  />
                  <div className="slider-content">
                    <h3>Apa itu Komite Teknis</h3>
                    <p>
                      Komite Teknis adalah komite yang dibentuk dan ditetapkan BSN, beranggotakan perwakilan Pemangku Kepentingan untuk lingkup tertentu dan bertugas melaksanakan perumusan SNI.
                    </p>
                  </div>
                </div>
                <div className="h-100 d-flex justify-content-center align-items-center bg-sunny-morning">
                  <div
                    className="slide-img-bg opacity-6"
                    style={{
                      backgroundImage: "url(" + bg2 + ")",
                    }}
                  />
                  <div className="slider-content">
                    <h3>AgroStandar Hebat, Pertanian Maju</h3>
                    <ul>
                      <li>Program strategis dalam menciptakan dan mengembangkan standardisasi instrumen pertanian</li>
                      <li>Peningkatan pelayanan kepada seluruh stakeholder menghadapi era persaingan global</li>
                      <li>Peningkatan daya saing bangsa melalui sektor pertanian yanng maju, mandiri, modern</li>
                    </ul>
                  </div>
                </div>
              </Slider>
            </div>
          </Col>
          <Col
            lg="6"
            md="12"
            className="h-100 d-flex justify-content-center align-items-center"
            style={{ backgroundColor: "#f7b924" }}
          >
            <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">
              {/* <div className="app-logo" /> */}
              <div
                style={{
                  // paddingTop: 60,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  src={logoKementan}
                  style={{ width: "20%", marginBottom: 100 }}
                />
              </div>

              <h4 className="mb-0" style={{ fontWeight: 600 }}>
                <div>Selamat datang,</div>
                <span>Silahkan login ke akun Anda.</span>
              </h4>
              {/* <h6 className="mt-3">
                                    No account?{" "}
                                    <a href="https://colorlib.com/" onClick={(e) => e.preventDefault()} className="text-primary">
                                        Sign up now
                                    </a>
                                </h6> */}
              <Row className="divider" />
              <Card
                className="card-animate bg-heavy-rain"
                style={{ margin: 10, marginTop: 40, paddingBottom: "1rem" }}
              >
                <CardHeader className="card-animate bg-night-sky text-white">
                  <i className="header-icon lnr-license icon-gradient bg-plum-plate ">
                    {" "}
                  </i>
                  Pilih Login
                  <div className="btn-actions-pane-right ">
                    <Button
                      size="sm"
                      outline
                      color="light"
                      className={
                        "btn-pill btn-wide " +
                        classnames({ active: activeTab === "1" })
                      }
                      onClick={() => {
                        toggle("1");
                      }}
                    >
                      BSIP
                    </Button>
                    <Button
                      size="sm"
                      outline
                      color="light"
                      className={
                        "btn-pill btn-wide me-1 ms-1 " +
                        classnames({ active: activeTab === "2" })
                      }
                      onClick={() => {
                        toggle("2");
                      }}
                    >
                      Komtek
                    </Button>
                  </div>
                </CardHeader>
                <CardBody>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <Form>
                        <Row>
                          <Col md={6}>
                            <FormGroup
                              style={{
                                marginTop: 5,
                                marginLeft: 20,
                                width: "90%",
                              }}
                            >
                              <Label
                                for="exampleEmail"
                                style={{ color: "#000" }}
                              >
                                Email
                              </Label>
                              <Input
                                type="email"
                                name="email"
                                id="exampleEmail"
                                placeholder="Masukkan Email"
                                value={form.email}
                                onChange={(e) => handleChange(e, "email")}
                                invalid={errEmail}
                              />
                              <FormFeedback>Harus diisi</FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup
                              style={{
                                marginTop: 5,
                                marginLeft: 0,
                                width: "90%",
                              }}
                            >
                              <Label
                                for="exampleNomor"
                                style={{ color: "#000" }}
                              >
                                No Handphone
                              </Label>
                              <Input
                                type="number"
                                name="text"
                                id="exampleNoHP"
                                placeholder="Masukkan No Handphone"
                                value={form.noHp}
                                onChange={(e) => handleChange(e, "noHp")}
                                invalid={errNohp}
                              />
                              <FormFeedback>Harus diisi</FormFeedback>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={8}></Col>
                          <Col md={4}>
                            <FormGroup
                              style={{
                                marginTop: 5,
                                marginRight: 20,
                                width: "90%",
                              }}
                            >
                              <Button
                                style={{ width: "90%" }}
                                color="primary"
                                className="mt-1"
                                onClick={handleSubmit}
                              >
                                <i className="lnr-location btn-icon-wrapper"> </i>
                                Login BSIP
                              </Button>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Form>
                    </TabPane>
                    <TabPane tabId="2">
                      <Form>

                        <Row>
                          <Col md={6}>
                            <FormGroup
                              style={{
                                marginTop: 5,
                                marginLeft: 20,
                                width: "90%",
                              }}
                            >
                              <Label
                                for="exampleEmail"
                                style={{ color: "#000" }}
                              >
                                Email
                              </Label>
                              <Input
                                type="email"
                                name="email"
                                id="exampleEmail"
                                placeholder="Masukkan Email"
                                value={form.emailKomtek}
                                onChange={(e) => handleChange(e, "emailKomtek")}
                                invalid={errEmailKomtek}
                              />
                              <FormFeedback>Harus diisi</FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup
                              style={{
                                marginTop: 5,
                                marginLeft: 0,
                                width: "90%",
                              }}
                            >
                              <Label
                                for="exampleNomor"
                                style={{ color: "#000" }}
                              >
                                No Handphone
                              </Label>
                              <Input
                                type="number"
                                name="text"
                                id="exampleNoHP"
                                placeholder="Masukkan No Handphone"
                                value={form.noHpKomtek}
                                onChange={(e) => handleChange(e, "noHpKomtek")}
                                invalid={errNohpKomtek}
                              />
                              <FormFeedback>Harus diisi</FormFeedback>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={8}></Col>
                          <Col md={4}>
                            <FormGroup
                              style={{
                                marginTop: 5,
                                marginRight: 20,
                                width: "90%",
                              }}
                            >
                              <Button
                                style={{ width: "90%" }}
                                color="primary"
                                className="mt-1"
                                onClick={handleSubmit}
                              >
                                <i className="lnr-location btn-icon-wrapper"> </i>
                                Login Komtek
                              </Button>
                            </FormGroup>
                          </Col>
                        </Row>

                      </Form>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Col>
        </Row>
      </div>

      <Modal isOpen={open} toggle={closeModal} size="xl" fullscreen>
        <ModalHeader className="card-animate bg-night-sky text-white">
          <div style={{ display: "flex" }}>
            <h5 className="modal-title">Verification Code</h5>
            {/* <Button type="button" onClick={closeBarang} className="btn-close" aria-label="Close">
            </Button> */}
          </div>
        </ModalHeader>
        <ModalBody className="card-animate bg-heavy-rain">
          <div>
            <form onSubmit={submitOtp}>
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: 50, marginBottom: -30, fontSize: 16 }}>
                Silahkan Periksa kode verifikasi di nomor whatsapp anda yg terdaftar
              </div>
              <div style={{ marginTop: 60 }}>
                <div className="timer-wrapper">
                  <CountdownCircleTimer
                    isPlaying
                    duration={60}
                    colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                    colorsTime={[60, 30, 10, 0]}
                    onComplete={() => ({ shouldRepeat: true, delay: 1 })}
                  >
                    {renderTime}
                  </CountdownCircleTimer>
                </div>
              </div>

              <div className="otpContainer" style={{ display: "flex", justifyContent: "center" }}>

                <input
                  name="otp1"
                  type="text"
                  autoComplete="off"
                  className="otpInput"
                  value={otp1}
                  onChange={(e) => changeOtp("otp1", e)}
                  tabIndex="1"
                  maxLength="1"
                  onKeyUp={(e) => inputfocus(e)}
                />
                <div style={{ fontWeight: "bold", marginTop: 10 }}> - </div>
                <input
                  name="otp2"
                  type="text"
                  autoComplete="off"
                  className="otpInput"
                  value={otp2}
                  onChange={(e) => changeOtp("otp2", e)}
                  tabIndex="2"
                  maxLength="1"
                  onKeyUp={(e) => inputfocus(e)}
                />
                <div style={{ fontWeight: "bold", marginTop: 10 }}> - </div>
                <input
                  name="otp3"
                  type="text"
                  autoComplete="off"
                  className="otpInput"
                  value={otp3}
                  onChange={(e) => changeOtp("otp3", e)}
                  tabIndex="3"
                  maxLength="1"
                  onKeyUp={(e) => inputfocus(e)}
                />
                <div style={{ fontWeight: "bold", marginTop: 10 }}> - </div>
                <input
                  name="otp4"
                  type="text"
                  autoComplete="off"
                  className="otpInput"
                  value={otp4}
                  onChange={(e) => changeOtp("otp4", e)}
                  tabIndex="4"
                  maxLength="1"
                  onKeyUp={(e) => inputfocus(e)}
                />
                <div style={{ fontWeight: "bold", marginTop: 10 }}> - </div>
                <input
                  name="otp5"
                  type="text"
                  autoComplete="off"
                  className="otpInput"
                  value={otp5}
                  onChange={(e) => changeOtp("otp5", e)}
                  tabIndex="5"
                  maxLength="1"
                  onKeyUp={(e) => inputfocus(e)}
                />
                <div style={{ fontWeight: "bold", marginTop: 10 }}> - </div>
                <input
                  name="otp6"
                  type="text"
                  autoComplete="off"
                  className="otpInput"
                  value={otp6}
                  onChange={(e) => changeOtp("otp6", e)}
                  tabIndex="6"
                  maxLength="1"
                  onKeyUp={(e) => inputfocus(e)}
                />

              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button className="mb-2 me-2 btn-icon" color="danger">
                  <i className="lnr-sync btn-icon-wrapper"> </i>
                  Kirim Ulang
                </Button>
                <Button
                  className="mb-2 me-2 btn-icon"
                  color="primary"
                  onClick={submitOtp}
                >
                  <i className="lnr-location btn-icon-wrapper"> </i>
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default Login;
