import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route, HashRouter } from "react-router-dom";
import configureStore from "./config/configureStore";

import Login from "./pages/login/Login";
// import Login from "./pages/login/LandingPage";

import AdminDashboard from "./pages/Main";
import { Provider } from "react-redux";

/*
import User from "./pages/User/User";
*/

const Routing = () => {
  const isLoggedIn = localStorage.getItem("status");
  const store = configureStore();
  const rootElement = document.getElementById("root");
  // console.log(isLoggedIn, 'IS OGIN')

  const renderApp = (Component) => {
    ReactDOM.render(
      <Provider store={store}>
        <HashRouter>
          <Component />
        </HashRouter>
      </Provider>,
      rootElement
    );
  };

  return (
    <Router>
      <Switch>

        <Route path="/">
          {
            isLoggedIn == "authenticated" ? renderApp(AdminDashboard) : <Login />
          }
        </Route>
        {/* <Route path="/landing-page"> {renderApp(LandingPage)} </Route> */}
      </Switch>
    </Router>
  );
};


export default Routing;
