import { Route, Redirect } from "react-router-dom";
import React, { Suspense, lazy, Fragment } from "react";
import Loader from "react-loaders";

import { ToastContainer } from "react-toastify";

const Pages = lazy(() => import("../../pages"));

const isLoggedIn = localStorage.getItem("status");
//import LoadingOverlay from "react-loading-overlay-ts";


const AppMain = () => {

    return (
        <Fragment>

            {/* Dashboards */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-sync" />
                            {/* <LoadingOverlay tag="div" active={true}
                                styles={{
                                    overlay: (base) => ({
                                        ...base,
                                        background: "#fff",
                                        opacity: 0.5,
                                    }),
                                }}
                                spinner={
                                    <Loader color="#ffffff" active type={this.state.loaderType} />
                                }
                            >
                            </LoadingOverlay> */}
                        </div>
                        <h6 className="mt-3">
                            Mohon Tunggu ...
                            <small>Aplikasi sedang memuat data!</small>
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/pages" component={Pages} />
            </Suspense>


            <Route exact path="/" render={() => (
                <Redirect to="/pages/dashboard" />
            )} />
            <ToastContainer />
        </Fragment>
    )
};

export default AppMain;
